<template>
    <div>
        <Toolbar
          :handle-reset="resetForm"
          :handle-submit="onSendForm"
        />
        <TenantSeminarSettingForm
          :errors="violations"
          :values="item"
          ref="updateForm"
          v-if="item"
        />
        <Loading :visible="isLoading"/>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { mapFields } from 'vuex-map-fields';
  import TenantSeminarSettingForm from '../../components/tenantSeminarSetting/Form.vue';
  import Loading from '@/components/Loading';
  import Toolbar from '@/components/Toolbar';
  import UpdateMixin from '../../mixins/UpdateMixin';

  const servicePrefix = 'TenantSeminarSetting';

  export default {
    name: 'TenantSeminarSettingUpdate',
    servicePrefix,
    mixins: [UpdateMixin],
    data () {
      return {
        item: {
          numberRange: {}
        },
        updateMessage: this.$t('tenantSeminarSettingUpdateSuccess')
      };
    },
    components: {
      Loading,
      Toolbar,
      TenantSeminarSettingForm,
    },
    computed: {
      ...mapFields('tenantSeminarSetting', {
        isLoading: 'isLoading',
        error: 'error',
        updated: 'updated',
        violations: 'violations'
      }),
      ...mapGetters('tenantSeminarSetting', ['find'])
    },
    methods: {
      ...mapActions('tenantSeminarSetting', {
        retrieve: 'load',
        update: 'update',
      })
    },
  }
</script>
